import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { restApi } from '../../../../../common/api';
import dayjs from 'dayjs';
import PaginationMoon from '../../../../../components/PaginationMoon';
import { shallowEqual, useSelector } from 'react-redux';
import Modal from '../../../../../components/Modal';
import produce from 'immer';
import { useLoading } from '../../../../../nav/AppContainer';
import { downloadLink } from '../../../../../common/utils';
import DownIco from '../../../../../components/icons/DownIco';
import FileSaver from 'file-saver';
import UpIco from '../../../../../components/icons/UpIco';
import img1 from '../../../../../assets/images/upload_sample.png'

export default function LmsExamsItemsIdDetailsUsers() {
  const codemap = useSelector((s) => s.common.codes?.map, shallowEqual);
  const query = useParams();

  const [params, setParams] = useState({});

  const [showExaminee, setShowExaminee] = useState(undefined);
  const [points, setPoints] = useState([]);
  const [type, setType] = useState();
  const [finishAt, setFinishAt] = useState();
  const [exam, setExam] = useState();
  const loadExam = useCallback(async () => {
    const { data } = await restApi.get(`/lectures/0/exams/${query.id}`);
    setExam(data);
  }, [query.id]);

  useEffect(() => {
    loadExam().catch(console.warn);
  }, [loadExam]);

  const [data, setData] = useState();
  const loadData = useCallback(async () => {
    if (!exam?.lecture?.id) return;
    const { data } = await restApi.get(`/lectures/${exam?.lecture?.id}/exams/${query.id}/users`, { params });
    setData(data);
  }, [query.id, exam?.lecture?.id, params]);

  useEffect(() => {
    loadData().catch(console.warn);
  }, [loadData]);

  const showExam = useMemo(() => {
    if (!showExaminee) return null;
    return data?.content?.find((x) => x.account?.id === showExaminee);
  }, [data?.content, showExaminee]);

  const [, setLoading] = useLoading();

  const [files, setFiles] = useState([]);
  useEffect(() => {
    if (!showExam?.item?.files) return;
    const loadData = async () => {
      const ids = [...showExam.item.files, ...exam.fileIds];
      const { data: fdata } = await restApi.get(`/files/infos`, { params: { ids } });
      setFiles(fdata?.map((x) => ({ id: x.id, filename: x.filename })));
      setType(showExam.item?.type)
    };
    loadData().catch(console.warn);
  }, [showExam?.item?.files]);

  const handleDownloadXls = async () => {
    setLoading(true);
    const filename = `시험_${exam?.title}_${dayjs().format('YYYYMMDD')}`;
    try {
      const { data } = await restApi.get(`/lectures/${exam?.lecture?.id}/exams/${query.id}/xlsx`, {
        responseType: 'arraybuffer',
      });
      FileSaver.saveAs(new Blob([data]), `${filename}.xlsx`);
    } catch (e) {
      alert('서버와 연결이 올바르지 않습니다.');
      console.warn(e);
    }
    setLoading(false);
  };

  const handleDownloadXls2 = async () => {
    setLoading(true);
    const filename = `시험_${exam?.title}_${dayjs().format('YYYYMMDD')}`;
    try {
      const { data } = await restApi.get(`/lectures/${exam?.lecture?.id}/exams/${query.id}/xlsx2`, {
        responseType: 'arraybuffer',
      });
      FileSaver.saveAs(new Blob([data]), `${filename}.xlsx`);
    } catch (e) {
      alert('서버와 연결이 올바르지 않습니다.');
      console.warn(e);
    }
    setLoading(false);
  };

  const handleDownloadZip = async (item) => {
    setLoading(true);
    // const filename = `과제_${item?.title}_${dayjs().format('YYYYMMDD')}`;
    const filename = `${showExam.account.id}_${showExam.account.name}_${item.filename}}`;
    try {
      const { data } = await restApi.get(`/files/infos`, {
        params: { ids: item.id },
      });
      const file = data[0];
      FileSaver.saveAs(downloadLink(file), file.filename);
    } catch (e) {
      alert('서버와 연결이 올바르지 않습니다.');
      console.warn(e);
    }
    setLoading(false);
  };

  const handleDownloadAll = async () => {
    const ids = data.content
      ?.map((x) => x?.item?.files)
      .filter((x) => !!x)
      .reduce((a, b) => a.concat(b), []);

    setLoading(true);
    try {
      const filename = `${codemap[exam?.lecture?.semester?.session]}_${exam?.lecture?.mainClass.title}_${
        exam?.title
      }_${dayjs().format('YYYYMMDD')}`;

      const { data: resp } = await restApi.get(`/lectures/${exam?.lecture?.id}/exams/${query.id}/zip`, {
        responseType: 'arraybuffer',
        params: {
          name: filename,
        },
      });
      FileSaver.saveAs(new Blob([resp]), `${filename}.zip`);
    } catch (e) {
      alert('서버와 연결이 올바르지 않습니다.');
    }
    setLoading(false);
  };

  const handleDownloadInfo = async () => {
    setLoading(true);
    try {
      const filename = `${dayjs().format('YYMMDD')}_${exam?.title} - ${exam?.lecture?.semester?.year}년 ${
        codemap[exam?.lecture?.semester?.session]
      } ${exam?.lecture?.mainClass?.title}`;

      const { data: resp } = await restApi.get(`/lectures/${exam?.lecture?.id}/exams/${query.id}/info`, {
        responseType: 'arraybuffer',
        params: {
          name: filename,
        },
      });
      FileSaver.saveAs(new Blob([resp]), `${filename}.zip`);
    } catch (e) {
      alert('서버와 연결이 올바르지 않습니다.');
    }
    setLoading(false);
  };


  const [uploadForm, setUploadForm] = useState(0);
  const handleUploadSubmit = async (e) => {
    e.preventDefault();

    const files = e.target.filedata.files;
    if (files.length === 0) {
      alert('파일선택은 필수항목입니다.');
      return;
    } else if (!files[0].name.endsWith('.xlsx')) {
      alert('허용된 파일은 xlsx입니다.');
      return;
    }

    setUploadForm(2);
    try {
      const formData = new FormData();
      formData.append('file', files[0]);
      formData.append('examId', query.id);

      const { data } = await restApi.post(`/lectures/${exam?.lecture?.id}/exams/upload/xlsx`, formData);
      if (data.error) {
        alert(
          '아래 사유로 엑셀업로드가 불가능합니다.\n===================\n' +
            data.message +
            '\n-' +
            data.items?.join('\n -'),
        );
        setUploadForm(1);
      } else {
        const msg =
          `${data.modifies.length}건의 점수가 반영되었습니다.`;
        alert(msg);
        await loadData();
        setUploadForm(0);
      }
    } catch (e) {
      console.warn(e);
      alert('서버와 연결이 올바르지 않습니다.');
      setUploadForm(1);
    }
    window.location.reload();
    // onClick={() => setUploadForm(2)}
  };

  return (
    <div className="content-wrapper container-xxl p-0">
      {/* header s */}
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">시험</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">시험</li>
                  <li className="breadcrumb-item active">시험</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // header e */}
      {/* con s */}
      <ul className="nav nav-tabs" role="tablist">
        <li className="nav-item">
          <Link className="nav-link " to={`/lms/exams/items/${query.id}`}>
            시험정보
          </Link>
        </li>
        {/* 시험 응시가 완료되었을 경우 */}
        <li>
          <Link className="nav-link " to={`/lms/exams/items/${query.id}/paper`}>
            시험지 보기
          </Link>
        </li>
        <li className="nav-item ">
          <Link className="nav-link active" to={`/lms/exams/items/${query.id}/users`}>
            응시자 보기
          </Link>
        </li>
      </ul>
      <div className="tab-content">
        <div className="tab-pane active" id="enn" aria-labelledby="enn-tab" role="tabpanel">
          {/* Bordered table start */}
          <div className="row">
            <div className="col-12">
              <h5 className="mb-75">응시 현황</h5>
              <div className="card">
                <div className="table-responsive">
                  <table className="table table-bordered visual-box">
                    <colgroup>
                      <col style={{ width: '11rem' }} />
                      <col style={{ width: '35%' }} />
                      <col style={{ width: '11rem' }} />
                      <col />
                    </colgroup>
                    <tbody>
                      <tr>
                        <th>
                          <label className="form-label">시험명</label>
                        </th>
                        <td >
                          {exam?.title} - {exam?.lecture?.semester?.year}년 {codemap[exam?.lecture?.semester?.session]}{' '}
                          {exam?.lecture?.mainClass?.title}
                        </td>
                        <th>
                          <label className="form-label">시험방식</label>
                        </th>
                        <td>
                        {exam?.type === 'EXAM' && (
                          <td>{exam?.isOffline ? '오프라인 시험' : ''}{(exam?.isOffline && exam?.examStyle != null) ? ', ' : ''}{exam?.examStyle === null ? '' : exam?.examStyle === 'ONLINE' ? '온라인 시험' : '시험지 다운로드'}</td>
                        )}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <label className="form-label">응시율</label>
                        </th>
                        <td>
                          {`${Math.floor((exam?.applySize / data?.totalElements) * 100)}% (${exam?.applySize}/${
                            data?.totalElements
                          }명)`}
                        </td>
                        <th>
                          <label className="form-label">문항유형(문항수)</label>
                        </th>
                        {exam?.examStyle === 'DOWNLOAD' ? (
                          <td className="d-flex align-items-center gap-1">
                            <div className={'flex-grow-1'}>시험지 업로드</div>
                            <div className={'flex-grow-1'}>{exam?.isOffline ? '오프라인 시험': ''}</div>
                            <button
                              type="button"
                              className="btn btn-outline-dark btn-doc waves-effect"
                              onClick={handleDownloadAll}
                              disabled={exam?.applySize == 0}
                            >
                              시험일괄 다운로드
                              <DownIco />
                            </button>
                            <button
                              type="button"
                              className="btn btn-outline-dark btn-doc waves-effect"
                              onClick={handleDownloadXls}
                              disabled={exam?.applySize == 0}
                            >
                              응시이력 다운로드
                              <DownIco />
                            </button>
                          </td>
                        ) : (
                          <td className="d-flex align-items-center gap-1">
                            {exam?.examStyle != null && <div className={'flex-grow-1'}>혼합형({exam?.questions?.length}문제)</div>}
                            <div className={'flex-grow-1'}>{exam?.isOffline ? '오프라인 시험': ''}</div>
                            <button
                              type="button"
                              className="btn btn-outline-dark btn-doc waves-effect"
                              onClick={handleDownloadInfo}
                              disabled={exam?.applySize == 0}
                            >
                              시험결과 다운로드
                              <DownIco />
                            </button>
                            <button
                              type="button"
                              className="btn btn-outline-dark btn-doc waves-effect"
                              onClick={handleDownloadXls}
                              disabled={exam?.applySize == 0}
                            >
                              응시이력 다운로드
                              <DownIco />
                            </button>
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="table-responsive">
                  <table className="table table-bordered visual-box">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>이름</th>
                        <th>기수</th>
                        <th>지역</th>
                        {exam?.examStyle === 'DOWNLOAD' && <th>시험지</th>}
                        <th>점수</th>
                        <th>시험 방식</th>
                        <th>응시 여부</th>
                        <th>응시 시작일시</th>
                        <th>응시 종료일시</th>
                        <th>재응시 여부</th>
                        <th>재응시 시작일시</th>
                        <th>재응시 종료일시</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.content?.map((item, index) => {
                        const isRetry =
                          exam?.retryAccounts && exam.retryAccounts.some((acc) => acc === String(item.account.id));
                        return (
                          <tr>
                            <td className="txt-c">{index + 1 + data?.size * data?.number}</td>
                            <td className="txt-c">
                              {!!item?.item?.type ? (
                                <a
                                  style={{ fontWeight: 'bold', textDecoration: 'underline' }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setShowExaminee(item.account.id);
                                    setPoints(item.item.points ? item.item.points : [0]);
                                  }}
                                >
                                  {item?.account?.name}
                                </a>
                              ) : (
                                item?.account?.name
                              )}
                            </td>
                            <td className="txt-c">IMBA {item?.account?.period}기</td>
                            <td className="txt-c"> {item?.account?.isForeign ? '해외' : '국내'}</td>
                            {exam?.examStyle === 'DOWNLOAD' && (
                              <td className="txt-c">
                                {item?.item?.files?.length > 0 && (
                                  <a
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setShowExaminee(item.account.id);
                                      setPoints(item.item.points);
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={14}
                                      height={14}
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-paperclip"
                                    >
                                      <path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48" />
                                    </svg>
                                  </a>
                                )}
                              </td>
                            )}
                            <td className="txt-c">
                              {!!item?.item && (item?.item?.type == 'CORRECT' || item?.item?.type == 'RETRY') && (
                                <a
                                  style={{ fontWeight: 'bold', textDecoration: 'underline' }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setShowExaminee(item.account.id);
                                    setPoints(item.item.points);
                                  }}
                                >
                                  {item.item.point}점
                                </a>
                              )}
                            </td>
                            <td className="txt-c">{!!item?.item?.type ? item?.item?.isOffline ? '오프라인 시험': (exam?.examStyle == 'DOWNLOAD' ? '다운로드 시험' : '온라인 시험') : ''}</td>
                            <td className="txt-c">{item?.item?.type == 'CORRECT' ? '응시완료' : '미응시'}</td>
                            <td className="txt-c">
                              {item?.item?.type == 'CORRECT' && item?.item?.type !== 'RETRY' &&
                                item?.item?.createdAt &&
                                dayjs(exam.startAt).format('YYYY.MM.DD HH:mm')}
                            </td>
                            <td className="txt-c">
                              {item?.item?.type !== 'RETRY' &&
                                item?.item?.finishAt &&
                                dayjs(item.item.finishAt).format('YYYY.MM.DD HH:mm')}
                            </td>
                            <td className="txt-c">{isRetry && (item?.item?.reCreatedAt ? '응시' : '미응시')}</td>
                            <td className="txt-c">
                              {isRetry &&
                                item?.item?.reCreatedAt &&
                                dayjs(item.item.reCreatedAt).format('YYYY.MM.DD HH:mm')}
                            </td>
                            <td className="txt-c">
                              {isRetry && item?.item?.finishAt && dayjs(item.item.finishAt).format('YYYY.MM.DD HH:mm')}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <button
                  type="button"
                  className="btn btn-outline-dark btn-doc waves-effect ms-1"
                  onClick={() => setUploadForm(1)}
                >
                  성적 업로드 <UpIco />
                </button>
              {/* pagination s */}
              <nav aria-label="Page navigation">
                <PaginationMoon data={data} onClick={(page) => setParams((x) => ({ ...x, page }))} />
              </nav>
              {/* // pagination e */}
            </div>
          </div>
        </div>
        {/* // tab 02 e : 시험 응시가 완료 되었을 경우 */}
      </div>
      {/* // con e */}
      <Modal visible={showExaminee !== undefined}>
        <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: 900 }}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">응시자 답안 확인</h5>
              <button type="button" className="btn-close" onClick={() => setShowExaminee(undefined)} />
            </div>
            <div className="modal-body">
              {!!showExam && (
                <div className="row" key={`em-${showExam.account.id}`}>
                  <div className="col-12">
                    <h5 className="mb-75">응시자 정보</h5>
                    <div className="card">
                      <div className="table-responsive">
                        <table className="table table-bordered visual-box">
                          <colgroup>
                            <col style={{ width: '11rem' }} />
                            <col style={{ width: '35%' }} />
                            <col style={{ width: '11rem' }} />
                            <col />
                          </colgroup>
                          <tbody>
                            <tr>
                              <th>
                                <label className="form-label">응시자명</label>
                              </th>
                              <td colSpan={1}>
                                {showExam.account.name} / IMBA {showExam.account?.period}기{' '}
                              </td>
                              <th>
                                <label className="form-label">시험방식</label>
                              </th>
                              <td>
                              {exam?.type === 'EXAM' && (
                                <td>{exam?.isOffline ? '오프라인 시험' : ''}{(exam?.isOffline && exam?.examStyle != null) ? ', ' : ''}{exam?.examStyle === null ? '' : exam?.examStyle === 'ONLINE' ? '온라인 시험' : '시험지 다운로드'}</td>
                              )}
                              </td>
                            </tr>
                            <tr>
                              <th>
                                <label className="form-label">응시여부</label>
                              </th>
                                {showExam.item.isOffline ? (
                                  <td>
                                    <div className="form-check form-check-inline">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="inlineRadioOptions"
                                          value={'CORRECT'}
                                          id="ra01"
                                          defaultChecked={showExam.item?.type == 'CORRECT'}
                                          checked={type ? type == 'CORRECT' : showExam.item?.type == 'CORRECT'} 
                                          onClick={(e) => {
                                            const type = e.target.value
                                             setType(type)
                                            //  setPoints(
                                            //   produce((draft) => {
                                            //     draft[0] = showExam.item?.points[0] ? showExam.item?.points[0] : 0;
                                            //   }),
                                            // );
                                          }}
                                        />
                                        <label className="form-check-label" htmlFor="ra01">
                                          응시
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="inlineRadioOptions"
                                          value={'EXAM'}
                                          id="ra02"
                                          defaultChecked={showExam.item?.type != 'CORRECT'}
                                          checked={type ? type == 'EXAM' : showExam.item?.type != 'CORRECT'}
                                          onClick={(e) => {
                                            const type = e.target.value
                                            setType(type)
                                            setPoints([0]);
                                          }}
                                        />
                                        <label className="form-check-label" htmlFor="ra02">
                                          미응시
                                        </label>
                                      </div>
                                    </td>
                                ) : (
                                  <td>
                                    {showExam.item?.type == 'CORRECT' ? '응시완료' : '미응시'}
                                  </td>
                                )}
                              
                              <th>
                                <label className="form-label">재응시여부</label>
                              </th>
                              <td>{showExam.item?.type == 'RETRY' && '재응시'}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <h5 className="mb-75">응시자 답안</h5>
                    <div className="card">
                      <div className="table-responsive">
                        <table className="table table-bordered visual-box">
                          <colgroup>
                            <col style={{ width: '11rem' }} />
                            <col />
                          </colgroup>
                          <tbody>
                            {exam?.examStyle === 'DOWNLOAD' ? (
                              showExam.item.isOffline ? (
                                <tr>
                                  <th>
                                    <label className="form-label">합산 점수</label>
                                  </th>
                                  <td colSpan={3}>
                                  <input
                                    value={points?.[0]}
                                    onChange={(e) => {
                                      if (type == 'EXAM' || type == null) {
                                        setType("CORRECT")
                                      }
                                      if(e.target.value.length == 0) {
                                        setType("EXAM")
                                      }
                                      setPoints(
                                        produce((draft) => {
                                          draft[0] = e.target.value.replace(/[^0-9.]+/g, '');
                                        }),
                                      );
                                    }}
                                    className={'form-control'}
                                    style={{ display: 'inline', width: 100 }}
                                    />
                                    <br/>
                                    <label className="form-label">※ 점수 입력 시 응시 여부는 자동으로 "응시"로 변경됩니다. 
                                    </label>
                                  </td>
                                </tr>
                              ) : 
                              <>
                                <tr>
                                  <th>
                                    <label className="form-label">문제지</label>
                                  </th>
                                  <td>
                                    {exam?.fileIds?.map((id) => {
                                      const file = files?.find((x) => String(x.id) === String(id));
                                      return (
                                        <a href={downloadLink(file)} target={'_blank'} rel="noreferrer">
                                          {file?.filename}
                                        </a>
                                      );
                                    })}
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    <label className="form-label">사용자 업로드</label>
                                  </th>
                                  <td>
                                    {showExam.item.files?.map((id) => {
                                      const file = files?.find((x) => String(x.id) === String(id));
                                      return (
                                        <a href={downloadLink(file)} target={'_blank'} rel="noreferrer">
                                          {file?.filename}
                                        </a>
                                      );
                                    })}
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    <label className="form-label">배점</label>
                                  </th>
                                  <td>
                                    <input
                                      value={points?.[0]}
                                      onChange={(e) => {
                                        setPoints(
                                          produce((draft) => {
                                            draft[0] = e.target.value.replace(/[^0-9.]+/g, '');
                                          }),
                                        );
                                      }}
                                      className={'form-control'}
                                      style={{ display: 'inline', width: 100 }}
                                    />
                                  </td>
                                </tr>
                              </>
                            ) : (
                              showExam.item.isOffline ? (
                                <tr>
                                  <th>
                                    <label className="form-label">합산 점수</label>
                                  </th>
                                  <td colSpan={3}>
                                  <input
                                    value={points?.[0]}
                                    onChange={(e) => {
                                      if (type == 'EXAM' || type == null) {
                                        setType("CORRECT")
                                      }
                                      if(e.target.value.length == 0) {
                                        setType("EXAM")
                                      }
                                      setPoints(
                                        produce((draft) => {
                                          draft[0] = e.target.value.replace(/[^0-9.]+/g, '');
                                        }),
                                      );
                                    }}
                                    className={'form-control'}
                                    style={{ display: 'inline', width: 100 }}
                                    />
                                    <br/>
                                    <label className="form-label">※ 점수 입력 시 응시 여부는 자동으로 "응시"로 변경됩니다. 
                                    </label>
                                  </td>
                                </tr>
                              ) : (
                              showExam.item.answers?.map((a, ix) => {
                                const question = exam?.questions?.[ix];
                                return (
                                  <React.Fragment key={`a-${ix}`}>
                                    <tr>
                                      <th rowSpan={3}>
                                        <label className="form-label">문제 {ix + 1}</label>
                                      </th>
                                      <td style={{ background: '#f2f4f7', fontWeight: 'bold' }}>
                                        {question.questionType === 'multiple-many' && (
                                          <small
                                            style={{
                                              display: 'block',
                                              color: '#acacac',
                                              fontSize: 14,
                                            }}
                                          >
                                            ※ 객관식(복수 정답)의 경우, 모든 답안을 맞춰야만 최종 정답으로 인정됩니다.
                                          </small>
                                        )}
                                        문제 : {question.question}
                                        <br />
                                        <br />답 :<br />
                                        {question.correctAnswers &&
                                          question.correctAnswers.map((correctAnswer, correctAnswerIndex) => (
                                            <React.Fragment key={`Correct-Answer-${correctAnswerIndex}`}>
                                              {correctAnswer}
                                              <br />
                                            </React.Fragment>
                                          ))}
                                      </td>
                                    </tr>
                                    <tr>
                                      {a.includes('|') ? (
                                        <td>
                                          제출 답안 :<br />
                                          {a.split('|').map((item, index) => (
                                            <span key={`a-${index}`}>
                                              {item}
                                              <br />
                                            </span>
                                          ))}
                                        </td>
                                      ) : (
                                        <td>제출 답안 : {a}</td>
                                      )}
                                    </tr>
                                    <tr>
                                      <td>
                                        점수 :
                                        <input
                                          value={points?.[ix]}
                                          onChange={(e) => {
                                            const value = e.target.value;
                                            if (value && !/^\d+(\.\d{0,2})?$/.test(value)) {
                                              e.target.value = value.slice(0, -1);
                                            } else {
                                              setPoints(
                                                produce((draft) => {
                                                  draft[ix] = value.replace(/[^0-9.]+/g, '');
                                                }),
                                              );
                                            }
                                          }}
                                          className={'form-control me-1 ms-1'}
                                          style={{ display: 'inline', width: 100 }}
                                        />
                                        배점({question.point})
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                );
                              }))
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary waves-effect waves-float waves-light"
                onClick={async () => {
                  const uri = `/lectures/${exam?.lecture?.id}/exams/${query.id}/apply/${showExaminee}`;
                  setLoading(true);
                    try {
                      if (!showExam?.item.isOffline) {
                      const maxPoint = exam?.questions?.map((x) => x.point);
                      for (let i = 0; i < maxPoint.length; i++) {
                        if (maxPoint[i] < parseInt(points[i], 10)) {
                          throw new Error(
                            `${i + 1}번째 점수가 올바르지 않습니다.\n현재점수 : ${points[i]} / 최대점수 : ${maxPoint[i]}`,
                          );
                        }
  
                        if (points[i] === '' || !/^\d+(\.\d{1,2})?$/.test(points[i])) {
                          throw new Error(
                            `${i + 1}번째 점수가 올바르지 않습니다.\n소수점 둘째 자리까지만 입력할 수 있습니다.`,
                          );
                        }
                      }
                    }
                    console.log(exam)
                    setFinishAt(exam.endAt);
                      // showExam?.item.isOffline ? (
                      //   await restApi.post(uri, { formData })
                      // ) : (
                        await restApi.post(uri, { points, type, finishAt })
                      // )
  
                      await loadData();
                      setShowExaminee(undefined);
                      setType(null);
                      alert('저장되었습니다.');
                    } catch (e) {
                      alert(e.message ?? '서버와 연결이 올바르지 않습니다.');
                    }                  

                  setLoading(false);
                  window.location.reload();
                }}
              >
                저장
              </button>
              <button
                type="button"
                className="btn btn-outline-primary waves-effect"
                onClick={() => {
                  setShowExaminee(undefined);
                  setType(undefined)
                }}
              >
                취소
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal className={'fade text-start modal-primary show'} visible={!!uploadForm}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">성적 업로드</h5>
              {uploadForm === 1 && <button type="button" className="btn-close" onClick={() => setUploadForm(0)} />}
            </div>
            <div className="modal-body">
              {uploadForm <= 1 ? (
                <form onSubmit={handleUploadSubmit}>
                  <div className="row mt-1">
                    <div className="col-12 mb-md-0">
                      <p>
                      <strong>&lt;성적 업로드 &gt; </strong><br/>
                      1. 성적 업로드를 위해 응시 이력 파일을 다운로드 해주세요. 
                      <button
                        type="button"
                        className="btn btn-outline-dark btn-doc waves-effect"
                        onClick={handleDownloadXls2}
                      >
                        응시이력 다운로드
                        <DownIco />
                      </button>
                        <br />
                        2. 엑셀파일에 평가 점수(합산 점수)를 입력한 후 저장해주세요.<br/>
                        (NO는 임의로 입력 가능, 평가점수 외 수정 불가)<br/>
                        3. 파일을 등록해 주세요.<br/>
                        4. 저장
                      </p>
                      <p>
                        <strong>&lt;성적 수정 &gt;</strong><br/>
                        1. 응시 이력 파일에 <a
                        style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                          성적을 수정하고자 하는 응시자의 평가점수를 작성
                          </a>하여 저장해주세요<br/>
                        (NO는 임의로 입력 가능, 평가점수 외 수정불가/이미지 참고)<br/>
                        <img src={img1} alt="img1" style={{ width: 454, height: 184 }} /><br/>
                        2. 파일을 등록해 주세요. <br/>
                        3. 저장
                      </p>
                    </div>
                    <div className="d-flex flex-column mt-2 mb-2">
                      <div className="featured-info">
                        {uploadForm === 1 && <input className="form-control" type="file" name={'filedata'} />}
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="submit" className="btn btn-primary waves-effect waves-float waves-light">
                      저장
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-primary waves-effect"
                      onClick={() => setUploadForm(0)}
                    >
                      취소
                    </button>
                  </div>
                </form>
              ) : (
                <div className="spinner-wrap">
                  <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                  <p>
                    원우정보를 DB에 업로드 중입니다.
                    <br />
                    잠시만 기다려 주십시오
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
